import {
  require
} from "./../../utils/http"
//分享海报
function getImg(data) {
  return require('post', '/api/hr24_ee/share/v1/poster_list/', data)
}
//查询企业微信二维码剩余时间
function wx_qr_time(data) {
  return require('post', '/api/hr24_ee/user/v1/wx_qr_time/', data)
}
//生成水印
function getwatermark(data) {
  return require('post', '/api/hr24_ee/share/v1/make_watermark/', data)
}
//公众号视频号模板
function get_video_photo(data) {
  return require('post', '/api/hr24_ee/share/v1/get_video_photo/', data)
}
//  获取企业微信二维码
function company_wx_qr(data) {
  return require('post', '/api/hr24_ee/company/v1/company_wx_qr/', data)
}
export {
  get_video_photo,
  getImg,
  getwatermark,
  company_wx_qr,
  wx_qr_time
}